import { Login, Logout, UserHasRole } from './app/utils/keycloak-authentication'
import WorkboxController from './app/utils/workbox-controller'

/**
 * This iife will try to do keycloak authentication ONLY IF USER IS AUTHENTICATED:
 *   It will load the LaunchApp module and execute the default function.
 * @see LaunchApp
 */
(async () => {
    console?.table(process.env)
    WorkboxController.Instance.register('/sw.js')

    const { authenticated } = await Login()

    if (authenticated) {
        if (UserHasRole(['Admin']) || UserHasRole(['RestrictedAdmin'])) {
            // see https://felfel.atlassian.net/browse/ET-641
            (await import('./app/scripts/launch-app')).default()
        } else {
            (await import('./app/scripts/unauthorized-application')).default()
        }
    } else {
        console.log('This is unexpected but user is not authenticated')
        Logout()
    }
})()

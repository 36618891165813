import axios from 'axios'
import get from 'lodash/get'
import { stringify } from 'querystring'
import { useMemo } from 'react'
import { formatDateCH, formatDateTimeCH, formatDateTimeUS, formatDateUS, newYorkTimezone, zurichTimezone } from './format-date'

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        Keycloak: any,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _keycloak: any,
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const keycloakRef = {
    current: createKeycloak()
}

/**
 * Create a new instance of Keycloak without running init function.
 */
function createKeycloak() {
    const KeycloakClass = window.Keycloak
    return new KeycloakClass(process.env.REACT_APP_KEYCLOAK_CONFIG_URL)
}

/**
 * Initialize the Keycloak object and return the authentication result.
 * This will throw an error if the keycloak.js is not loaded before this function.
 */
export async function Login() {
    const keycloak = keycloakRef.current
    try {
        await keycloak.init({ onLoad: 'login-required', checkLoginIframe: false })
        window._keycloak = keycloakRef
        return { keycloak, authenticated: true }
    } catch {
        return { keycloak, authenticated: false }
    }
}

/**
 * Check if the authentication tokien is expired and update it ONLY IF IT IS NEEDED.
 *
 * IT IS NEEDED WHEN:
 *   The token is expired OR it is going to be expired in less time than provided minValidity.
 *
 * @param minValidity in seconds (@default process.env.REACT_APP_KEYCLOAK_MIN_VALIDITY).
 */
export async function UpdateToken(
    minValidity = parseInt(process.env.REACT_APP_KEYCLOAK_MIN_VALIDITY),
) {
    const keycloak = keycloakRef.current
    try {
        await keycloak.updateToken(minValidity) // Min validitiy in seconds
        return { keycloak, authenticated: true }
    } catch {
        return { keycloak, authenticated: false }
    }
}

/**
 * Check if user contains any of the roles.
 * 
 * @param userRoles array with roles used validate against the user.
 */
export function UserHasRole(roles: string[] = []) {
    const userRoles = TokenParsed().realm_access.roles

    for (const role of roles) {
        if (userRoles.includes(role)) {
            return true
        }
    }
    return false
}

/**
 * Run the Logout function of the previously made keycloak object.
 */
export async function Logout() {
    const keycloak = keycloakRef.current
    keycloak.logout()
}

export interface TokenParsedData {
    email: string
    exp: number
    sub: string
    operatorId?: string
    realm_access: {
        roles: string[]
    }
}

/**
 * Return the Parsed id token which contains the users information.
 * This will structured as defined in mapper configurations of Keycloak's clients.
 */
export function TokenParsed() {
    const keycloak = keycloakRef.current
    return keycloak.tokenParsed as TokenParsedData
}

/**
 * Return the Parsed id token which contains the users information.
 * This will structured as defined in mapper configurations of Keycloak's clients.
 */
export function IdTokenParsed() {
    const keycloak = keycloakRef.current
    return keycloak.idTokenParsed
}

export function AccessToken() {
    const keycloak = keycloakRef.current
    return keycloak.token
}

export function RefreshToken() {
    const keycloak = keycloakRef.current
    return keycloak.refreshToken
}

export function ApplicationUserId() {
    return IdTokenParsed().sub
}

/**
 * Repalce the keycloak object with an upgraded object containing a RPT token with authorization attribute.
 * 
 * @see https://www.keycloak.org/docs/latest/authorization_services/index.html#_getting_started_hello_world_enabling_authz_services
 * @see https://www.keycloak.org/docs/latest/authorization_services/index.html#_service_obtaining_permissions
 * 
 * @param permissions List of resource (+ scope) names
 * @param audience Authorization client name in keycloak.
 */
export async function RequestRpt(
    permissions: string[],
    audience: string = process.env.REACT_APP_KEYCLOAK_AUTHORIZATION_AUDIENCE
) {
    if (permissions.length > 0) {
        const keycloak = keycloakRef.current
        const { data } = await axios.post(
            keycloak.endpoints.token(),
            stringify({
                grant_type: 'urn:ietf:params:oauth:grant-type:uma-ticket',
                audience,
                permission: permissions, // Will be parsed as permission=A&permission=B&...
            }),
            { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
        )

        const KeycloakClass = window.Keycloak
        keycloakRef.current = new KeycloakClass(process.env.REACT_APP_KEYCLOAK_CONFIG_URL)
        await keycloakRef.current.init({
            // onLoad: 'login-required',
            checkLoginIframe: false,
            token: data['access_token'],
            refreshToken: data['refresh_token']
        })
    } else {
        console.warn('Permissions list is empty!')
    }
}

export function Origin() {
    return new URL(keycloakRef.current.endpoints.token()).origin
}


interface ResourcePermission {
    rsname: string
    scopes: string[]
}

export function AuthorizationPermissions() {
    return get(TokenParsed(), ['authorization', 'permissions'], []) as ResourcePermission[]
}
/**
 * Return currency depending on iss domain
 */
export function CurrencyFromAuth() {
    const keycloak = keycloakRef.current
    if (keycloak.idTokenParsed.iss.includes('.ch')) return 'CHF'
    return '$'
}
/**
 * Return memoized CurrencyFromAuth to use in a React Component or Hook
 */
export function useCurrency() {
    return useMemo(CurrencyFromAuth, [])
}
/**
 * Return boolean depending on iss domain
 */
export function IsSwiss() {
    const keycloak = keycloakRef.current
    if (keycloak.idTokenParsed.iss.includes('.ch')) return true
    return false
}
/**
 * Return memoized IsSwiss to use in a React Component or Hook
 */
export function useIsSwiss() {
    return useMemo(IsSwiss, [])
}
/**
 * Return date format depending on iss domain
 */
export function DateFormatFromAuth() {
    const keycloak = keycloakRef.current
    if (keycloak.idTokenParsed.iss.includes('.ch')) return formatDateCH
    return formatDateUS
}
/**
 * Return memoized DateFormatFromAuth to use in a React Component or Hook
 */
export function useDateFormat() {
    return useMemo(DateFormatFromAuth, [])
}
/**
 * Return date format depending on iss domain
 */
export function DateTimeFormatFromAuth() {
    const keycloak = keycloakRef.current
    if (keycloak.idTokenParsed.iss.includes('.ch')) return formatDateTimeCH
    return formatDateTimeUS
}
/**
 * Return memoized DateFormatFromAuth to use in a React Component or Hook
 */
export function useDateTimeFormat() {
    return useMemo(DateTimeFormatFromAuth, [])
}
/**
 * Return timezone depending on iss domain
 */
export function TimezoneFromAuth() {
    const keycloak = keycloakRef.current
    if (keycloak.idTokenParsed.iss.includes('.ch')) return zurichTimezone
    return newYorkTimezone
}
/**
 * Return memoized TimezoneFromAuth to use in a React Component or Hook
 */
export function useTimezone() {
    return useMemo(TimezoneFromAuth, [])
}

/**
 * Return temperature unit depending on iss domain
 */
export function TemperatureUnitBasedOnTenant() {
    const keycloak = keycloakRef.current
    if (keycloak.idTokenParsed.iss.includes('.ch')) return '°C'
    return '°F'
}
/**
 * Return memoized TemperatureUnitBasedOnTenant to use in a React Component or Hook
 */
export function useTemperatureUnit() {
    return useMemo(TemperatureUnitBasedOnTenant, [])
}
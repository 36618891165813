import { format } from 'date-fns'
import { isString } from 'lodash'

export const zurichTimezone = 'Europe/Zurich'
export const newYorkTimezone = 'America/New_York' 

export const formatDateCH = 'dd.MM.yyyy'
export const formatDateTimeCH = 'dd.MM.yyyy HH:mm'
export const formatDateUS = 'MM/dd/yyyy'
export const formatDateTimeUS = 'MM/dd/yyyy HH:mm'
// this US format is used in the app and in my-felfel for transaction history for exemple
export const formatDateTimeUSInApp = 'MMM d, h:mm a'

/**
 * Format a date based on the tenant with Swiss or US date convention
 */
export default function FormatDate(isSwiss: boolean, date?: string | Date, withTime = false) {
    if (date === undefined) {
        return undefined
    } else {
        try {
            const dateFormat = withTime ? (isSwiss ? formatDateTimeCH : formatDateTimeUS) : (isSwiss ? formatDateCH : formatDateUS)

            if(isString(date) && withTime) {
                const regex = /[^+]+\+[^+]+/gm

                if (regex.test(date)) { // if it has TimeZoneOffset
                    return format(new Date(date), dateFormat)
                } else { // if it is a naive datetime string add utc
                    return format(new Date(date + '+00:00'), dateFormat)
                }
            } else {
                return format(new Date(date), dateFormat)
            }
        } catch {
            return undefined
        }
    }
}